@use "sass:math";
@use '~@THC/styles/core' as core;
@use '~@THC/styles/breakpoints';

    
@import '/utilities/styles/common';

.logoRegular {
	@include resetButtonStyles;

	padding: 0 core.$space2;

	@include breakpoints.minTablet {
		padding: 0 core.$space2 0 0;
	}
}

.logo > svg {
	overflow-x: overlay;
}
