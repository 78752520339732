@use "sass:math";
@use '~@THC/styles/_typography' as typography;

@use '~@THC/styles/_core' as core;

@use '~@THC/styles/_color' as color;

p.dealerName {
	@include typography.regular;

	padding: 0;

	&.primary {
		color: color.$primary-black;
	}

	&.secondary {
		color: color.$primary-white;
	}
}

p.dealershipName {
	@include typography.small;

	color: color.$light-grey-1;
	padding: 0;
}
