@use "sass:math";
@use '~@THC/styles/breakpoints';

@use '~@THC/styles/color';

@use '~@THC/styles/core';

@use '~@THC/styles/typography';

.content {
	align-items: center;
	display: flex;
	flex-direction: column;
	grid-area: content;
	row-gap: core.$space3;

	&Title {
		text-align: center;

		@include typography.heading3();

		@include breakpoints.minTabletMobile {
			@include typography.heading2-base();
		}
	}

	& .mainContent {
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: core.$space3;
		margin-top: core.$space3;

		.feedback {
			text-align: left;
		}

		.contentOtpResult {
			--thc-icon-accent-fill: #{color.$primary-black};
			--thc-font-color: #{color.$secondary-green};
		}
	}

	& p {
		padding-bottom: 0;
		text-align: center;
	}

	> div {
		max-width: calc(#{core.$space} * 64);
	}

	& .actions {
		display: flex;
		flex-direction: column;
		gap: core.$space3;
		margin-top: core.$space5;
		width: 100%;
	}
}

.optSuccessForm {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: core.$space4;

	--thc-icon-fill: #{color.$secondary-green};

	& p:last-child {
		padding-bottom: 0;
	}

	&Description {
		text-align: center;
	}
}

.endPhone {
	font-weight: typography.$font-weight-semi;
}
