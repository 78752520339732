@use "sass:math";
@use '@THC/styles/color' as color;

@use '@THC/styles/core' as core;

@use '~Utilities/styles/zindex' as zIndex;

@use '~Utilities/styles/siteNavigation' as siteNavigation;

.content {
	animation-delay: 0ms;
	animation-duration: 300ms;
	animation-name: slide-down-and-fade;
	animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
	border-radius: core.$border-radius;
	box-shadow: 0 0 core.$space3 0 rgba(0 0 0 / 25%);
	display: flex;
	flex-direction: column;
	margin-top: core.$space6 + core.$space1;
	padding: siteNavigation.$menuContentSpacing;
	position: absolute;
	text-align: center;
	top: core.$space2;
	z-index: zIndex.$siteNavigation;

	// Side

	&.center {
		left: 50%;
		transform: translate(-50%, 0);
	}

	&.left {
		left: 0;
	}

	&.right {
		right: 0;
	}

	// Size

	&.small {
		min-width: core.$space * 28;
	}

	&.regular {
		min-width: core.$space * 30;
	}

	&.large {
		min-width: core.$space * 35;
	}

	// Variation

	&.primary {
		background-color: color.$primary-white;
		color: color.$primary-black;
	}

	&.secondary {
		background-color: color.$dark-grey-1;
		color: color.$primary-white;
	}
}

@keyframes slide-down-and-fade {
	from {
		opacity: 0;
		top: 0;
	}

	to {
		opacity: 1;
		top: core.$space2;
	}
}
