@use "sass:math";
@use '~@THC/styles/core' as core;

@use '~@THC/styles/_color' as color;

@use '~Utilities/styles/zindex' as zIndex;

@use '~@THC/styles/_breakpoints' as breakpoints;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import '/utilities/styles/common.scss';

div.headerContainer {
	background-color: color.$primary-black;
	position: relative;
	transition: background-color 0.2s linear;
	z-index: zIndex.$siteNavigation;

	&.isTransparent {
		background-color: transparent;
	}

	&.isSticky {
		position: sticky;
		top: 0;
	}

	&.bottomBorder {
		border-bottom: 1px solid color.$dark-grey-2;
	}
}

header.header {
	color: color.$primary-white;
	display: flex;
	height: var(--mw-dwa-header-height-mobile);
	padding: 0 core.$space3 0 core.$space3;

	@media (min-width: $breakpointTablet) {
		align-self: center;
		height: var(--mw-dwa-header-height-desktop);
		margin: auto;
		max-width: core.$space * 230;
		padding: 0 core.$space7;
	}

	.logo {
		align-items: center;
		display: flex;
	}
}

.nav {
	@include defaultNavStyles;

	&.loggedIn {
		@media (min-width: $breakpointTablet) {
			justify-content: space-between;
		}
	}

	/**
	*	useBreakpoint doesn't work on SSR
	* Hide the desktop navigation on mobile
	* till DOM hydrates and removes desktop navigation.
	*/

	@include breakpoints.maxTablet {
		&.hideDesktopOnMobileTillHydration {
			display: none;
		}
	}
}
